#coaching_edit_modal {
    .modal-dialog {
        max-width: 802px;
        background: #FFFFFF;
        box-shadow: 0px 1px 120px rgba(0, 0, 0, 0.12);
        border-radius: 20px;

        .modal-body {
            padding: 50px;

            .connection_modal_right {

                .rbc-btn-group {
                    display: none;
                }

                .rbc-row-segment {

                    .rbc-event {
                        width: 13%;
                    }
                }

                .connection_modal_header {
                    margin-bottom: 33px;

                    .header_left {
                        .connection_title {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 36px;
                            color: #303134;
                        }

                        .member_name {
                            margin-top: 10px;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #D7D7D7;
                        }
                    }

                    .close_button {
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }

                .form_row {
                    margin-bottom: 30px;

                    .form_label {
                        padding: 0;
                        margin-bottom: 12px;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #717579;
                    }

                    .form_control {
                        width: 100%;
                        padding: 16px 22px;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: #303134;
                        border: 1px solid #D7D7D7;
                        border-radius: 14px;
                        background-color: transparent;

                        &.form_date {
                            cursor: pointer;
                            background: url('../../../../images/form/form_bg_calendar.svg') center right 15px no-repeat;
                        }

                        &.form-select {
                            cursor: pointer;
                            background: url('../../../../images/form/form_bg_select.svg') center right 15px no-repeat;
                        }
                    }

                    .last_connection_info {
                        padding: 16px 22px;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: #303134;
                    }
                }
            }

            .card-footer {
                margin-top: 55px;

                .btn_submit {
                    padding: 18px 34px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #FFFFFF;
                    background: #1B48BB;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
                    border-radius: 14px;

                    img {
                        margin-right: 27px;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}