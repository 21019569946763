//
// Modal
//

// Base
.modal {
    // Mobile header
    .modal-header {
        align-items: center;
        justify-content: space-between;
        @include border-top-radius($modal-content-border-radius);

        // Headings
        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 0;
        }

        // override for $root-font-size in src/_library/assets/sass/core/layout/_base.scss
        // https://react-bootstrap.netlify.app/docs/components/modal#modalheader has it at 24px, so this puts it at 26px
        h4 {
            font-size: 2rem;
        }
    }

    // Mobile dialog
    .modal-dialog {
        outline: none !important;
    }
}

// Utilities
.modal-rounded {
    @if $enable-rounded {
        border-radius: $modal-content-border-radius !important;
    }
}
