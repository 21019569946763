#new-discipline-modal, .corrective-action-modal {

	table.discipline-info {
		span.label {
			font-weight: bold;
		}

		td {
			border: solid darkgray thin;
			width: 50%;
		}

	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.chevron-background {
		position: relative;
		cursor: pointer;

		.chevron-inner {
			display: inline-block;
			position: relative;
			width: 160px;
			height: 70px;
			text-align: center;
			box-sizing: border-box;
			border-radius: 10px;
			background-color: whitesmoke;
			-webkit-clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 15% 50%);
			clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 15% 50%);
			transition: background-color 0.3s ease;

			&.active {
				background-color: deepskyblue;
			}

			span.warning-step-number {
				font-size: larger;
				margin-left: 10px;
				font-weight: 600;
			}

			span.warning-label {
				margin-left: 15px;
				color: #030303;
				font-size: 0.9rem;
			}
		}

		&:hover .chevron-inner {
			transform: scale(1.05);
		}
	}
	@media screen and (max-width: 768px) { /* Adjust the width as per your requirements */
		.modal-footer {
		  flex-direction: column-reverse;
		}
	  }



}
