.risk-block {
    height: 90%;

    .risk_item {
        margin-bottom: 24px;

        .symbol {
            margin-right: 16px;

            img {
                border-radius: 50%;
            }
        }

        .item_content {
            .item_name {
                .item_name_link {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 160%;
                    letter-spacing: 0.2px;
                    color: #0F172A;

                    &:hover {
                        color: #2563EB;
                    }
                }
            }

            .item_description {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 160%;
                color: #000000;

                .item_team_member {
                    color: #2563EB;
                }
            }
        }
    }
}
