.advanced_search_container {
    .dropdown_select {
        .dropdown_select_button {
            padding: 8px !important;
            border-radius: 8px;
            background: #ffffff;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 160%;
            color: #64748B;

            &.active {
                background: #8FD5FC;
            }

            span {
                i {
                    color: #64748B;
                }
            }

            &:hover {
                background: #8FD5FC;
            }
        }
    }

    .result_wrapper {
        margin-top: 15px;
        // height: calc(100vh - 245px);
        overflow-y: auto;

        .result_title {
            margin-top: 30px;
            margin-bottom: 25px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.2px;
            color: #0F172A;
        }

        .result_item {
            padding: 16px;
            background: #FFFFFF;
            border-radius: 12px;

            .result_item_left {
                .left_top {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: 0.2px;
                    color: #0F172A;
                }

                .left_bottom {
                    margin-top: 4px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 160%;
                    color: #81878A;
                }
            }

            .result_item_right {
                .item_date {
                    padding: 8px;
                    margin-right: 88px;
                    background: #F8FAFC;
                    border-radius: 8px;

                    img {
                        margin-right: 4px;
                    }

                    span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 160%;
                        color: #64748B;
                    }
                }

                >img {
                    width: 32px;
                    height: auto;
                    margin-right: 32px;
                    border-radius: 50%;
                }

                .btn_detail {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 14px;
                    color: #757575;
                }
            }
        }

        .result_connection_item {
            padding: 16px;
            background: #FFFFFF;
            border-radius: 12px;

            .automation_name {
                width: 150px;
                // color: #FC5A5A;
                color: #000;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 160%;
            }

            .connection_type,
            .team_department {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 160%;
                color: #000000;
            }

            .team_department {
                width: 200px;
            }

            .connection_comment {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 232px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 160%;
                color: #64748B;
            }

            .result_item_left {}

            .result_item_right {
                min-width: 285px;

                .item_date {
                    padding: 8px;
                    margin-right: 88px;
                    background: #F8FAFC;
                    border-radius: 8px;

                    img {
                        margin-right: 4px;
                    }

                    span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 160%;
                        color: #64748B;
                    }
                }

                >img {
                    width: 32px;
                    height: auto;
                    margin-right: 32px;
                    border-radius: 50%;
                }

                .btn_detail {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 14px;
                    color: #757575;
                }
            }
        }
    }
}