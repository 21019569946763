.actions_wrapper {
    position: relative;

    div.menu {
        position: absolute;
        display: block;
        right: 0;
        top: 15px;

        &.dropdown-action-menu {
            width: 165px;
        }
    }
}
