#kt_aside.aside {
    // width: 250px;

    .aside-logo {
        height: 88px;
        padding: 0 32px;
    }

    .aside_logo_divider_wrapper {
        padding: 0 16px;

        .aside_logo_divider {
            border-top: 1px solid #F1F5F9;
        }
    }

    .aside-menu {
        // width: 250px;

        .menu {
            .menu-item {
                padding-left: 16px;
                padding-right: 16px;

                .menu-content {
                    padding-left: 16px;
                    padding-right: 16px;

                    .menu-section {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 160%;
                        letter-spacing: 1px;
                        color: #94A3B8;
                    }
                }

                .menu-link {
                    padding-left: 16px;
                    padding-right: 16px;
                    color: #64748B;

                    .team-bullet {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        margin-right: 16px;
                        border-radius: 50%;
                        background: #6366F1;
                    }

                    .menu-icon {
                        .svg-icon {
                            svg [fill]:not(.permanent):not(g) {
                                fill: #64748B;
                            }

                            svg [stroke]:not(.permanent):not(g) {
                                stroke: #64748B;
                            }
                        }
                    }

                    .menu-title {
                        max-width: 165px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 160%;
                        letter-spacing: 0.2px;
                    }

                    &.active,
                    &:hover:not(.disabled):not(.active):not(.here) {
                        color: #2563EB;
                        background: #F8FAFC;
                        border-radius: 12px;

                        .menu-icon {
                            .svg-icon {
                                svg [fill]:not(.permanent):not(g) {
                                    fill: #2563EB;
                                }

                                svg [stroke]:not(.permanent):not(g) {
                                    stroke: #2563EB;
                                }
                            }
                        }

                        .menu-title {
                            color: #2563EB;
                        }
                    }
                }
            }
        }
    }
}